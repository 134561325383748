﻿@import '../variables/colors';
@import '../variables/fonts';
@import '../variables/z-index';
@import '../globals/media-queries';

.hero-slider {
    width: 100%;
    font-family: $font-primary;
    position: relative;
    height: 640px;
    margin-top: -7rem;
    z-index: $z-index-carousel-content;

    @include screen--md-up($withoutSuffix: true) {
        height: 640px;
        margin-top: 0;
    }

    @include screen--lg-up($withoutSuffix: true) {
        height: 721px;
    }

    &__container {
        height: 100%;
    }

    &__image {
        opacity: 1;
    }

    &__content {
        position: absolute;
        width: 80%;
        bottom: 14%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 2;

        @include screen--md-up($withoutSuffix: true) {
            width: 537px;
            left: 10%;
            bottom: 0%;
            align-items: flex-start;
            height: 330px;
        }

        @include screen--lg-up($withoutSuffix: true) {
            bottom: 14%;
        }

        @include screen--elg-up($withoutSuffix: true) {
            left: 17%;
        }
    }

    &__label {
        font-weight: $font-weight-bold;
        font-size: 14px;
        line-height: 20px;
        color: $color-yellow;

        @include screen--lg-up($withoutSuffix: true) {
            font-size: 16px;
        }
    }

    &__title {
        font-weight: $font-weight-bold;
        font-size: 24px;
        line-height: 40px;

        @include screen--lg-up($withoutSuffix: true) {
            font-size: 16px;
        }
    }

    &__description {
        font-weight: $font-weight-regular;
        font-size: 14px;
        line-height: 18px;
        color: $color-grey-lightest;
        padding-top: 22px;
        text-align: center;

        @include screen--md-up($withoutSuffix: true) {
            text-align: left;
        }

        @include screen--lg-up($withoutSuffix: true) {
            font-size: 18px;
            text-align: left;
            padding-top: 27px;
            line-height: 28px;
        }
    }

    &__button {
        width: min-content;
        margin-top: 30px;

        @include screen--md-up($withoutSuffix: true) {
            margin-top: 50px;
        }
    }

    &__slide {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    /*    flickity has been added here to modify a few of its elements and to not interfere with other flickity elements on the page*/
    .flickity-viewport {
        height: 100%;
    }

    .flickity-button {
        background: transparent;
        border-radius: none;
        visibility: hidden;
        z-index: 2;
        top: 60%;

        @include screen--md-up($withoutSuffix: true) {
            visibility: visible;
        }
    }

    .flickity-button-icon {
        fill: $color-grey-light;
    }

    .flickity-page-dots {
        bottom: 5%;
        z-index: 2;

        .dot {
            border: 1.2px solid $color-white;
            opacity: 1;
            width: 8px;
            height: 8px;
            background: transparent;
            margin: 0 6px;
        }

        .is-selected {
            background: $color-white;
        }
    }
    /* Fade CSS - Hack to make flickity fade */
    .flickity-slider {
        transform: none !important;
    }

    .hero-slider__slide {
        left: 0 !important;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }

    .hero-slider__slide.is-selected {
        opacity: 1;
    }
}
