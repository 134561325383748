﻿$color-white: #FFFFFF;

$color-black: #000000;
$color-black-light: #111111;
$color-black-lighter: #222222;
$color-black-lightest: #2C2C2C;

$color-grey: #3B3B3B;
$color-grey-light: #717171;
$color-grey-lighter: #B8B8B8;
$color-grey-lightest: #DEDEDE;
$color-grey-white: #F6F6F6;

$color-green: #20B787;
$color-green-light: #1FCC95;
$color-pink: #FF3F6C;
$color-pink-light: #FF6589;
$color-yellow: #FFF100;
$color-yellow-light: #FFF766;
$color-blue-dark: #3B5998;
$color-blue-medium: #03A9F4;
$color-blue: #5CDEDA;
$color-blue-light: #9DEBE9;
