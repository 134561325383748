﻿@import '../variables/colors';
@import '../variables/fonts';
@import '../variables/z-index';
@import '../globals/media-queries';

.search-overlay {
    display: flex;
    position: absolute;
    left: 0;
    background: $color-black-light;
    margin: 0 calc(50% - 50vw);
    width: 100vw;
    visibility: hidden;
    min-height: 150px;
    z-index: $z-index-navigation-elements;

    @include screen--md-up($withoutSuffix: true) {
        top: 73px;
        visibility: visible;
        transform: scaleY(0);
        min-height: auto;
        transform-origin: top center;
    }

    &--transition {
        transition: transform 0.3s ease-in-out;
    }

    &--active {
        visibility: visible;

        @include screen--md-up($withoutSuffix: true) {
            transform: scaleY(1);
        }
    }

    &__container {
        max-width: $breakpoint-large;
        margin: 0 auto;
        width: 100%;
        position: relative;
        margin-top: 123px;

        @include screen--md-up($withoutSuffix: true) {
            margin-top: 0;
        }
    }

    &__headline {
        padding: 0 1rem;
        font-size: 24px;

        @include screen--md-up($withoutSuffix: true) {
            font-size: 18px;
        }
    }

    &__input {
        height: 43px;
        width: 84%;
        display: block;
        margin: 1rem auto;
        background: $color-black-lightest;
        border-radius: 5px;
        font-family: $font-primary;
        color: $color-grey-white;
        padding: 0 1rem;

        @include screen--md-up($withoutSuffix: true) {
            width: 80%;
        }

        label {
            font-family: $font-primary;
        }
    }

    &__button {
        position: absolute;
        right: 2.5%;
        top: 37px;
        transition: opacity 0.2s ease-in-out;
        visibility: hidden;
        opacity: 0;

        @include screen--md-up($withoutSuffix: true) {
            right: 8.5%;
            top: 11px;
        }

        &--active {
            visibility: visible;
            opacity: 1;
        }
    }
}
