﻿@import '../variables/colors';
@import '../variables/fonts';
@import '../globals/media-queries';
@import 'layout';

// blog has two type of cards: large (featured article cards) and small (regular article cards)
// frontpage has one type of card, but uses a bit of both small and large's design.

.card {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.09), 0px 2px 6px rgba(0, 0, 0, 0.09), 0px 0px 1px rgba(0, 0, 0, 0.09);
    border-radius: 5px;
    background-color: $color-white;
    font-family: $font-primary;
    text-align: left;
    overflow: hidden;
    margin-bottom: 24px;
    cursor: pointer;
    transition: box-shadow 0.5s ease;
    position: relative;
    width: 100%;

    @include screen--lg-up($withoutSuffix: true) {
        flex: 1;
    }

    &--frontpage,
    &--blog-large {
        &:first-child {
            margin-right: 1rem;

            @include screen--md-up($withoutSuffix: true) {
                margin-right: 0;
            }

            @include screen--lg-up($withoutSuffix: true) {
                margin-right: 0.5rem;
            }
        }

        &:last-child {
            margin-left: 1rem;

            @include screen--md-up($withoutSuffix: true) {
                margin-left: 0;
            }

            @include screen--lg-up($withoutSuffix: true) {
                margin-left: 0.5rem;
            }
        }
    }

    &__link {
        text-decoration: none;
        position: absolute;
        height: 100%;
        width: 100%;
    }

    &:hover {
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2), 0px 2px 6px rgba(0, 0, 0, 0.2), 0px 0px 1px rgba(0, 0, 0, 0.2);
    }

    &--frontpage {
        @include standard-card-large-layout();
        height: 215px;

        @include screen--md-up($withoutSuffix: true) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &--blog-small {
        @include standard-card-small-layout();
        margin: 19px 2rem;
        flex: unset;

        @include screen--lg-up($withoutSuffix: true) {
            grid-template-columns: repeat(5, 1fr);
            margin: 10px 0;
        }
    }

    &--blog-large {
        @include standard-card-large-layout();
        grid-template-columns: 1fr 0.5fr repeat(3, 1fr);
        margin-bottom: 15px;

        @include screen--md-up($withoutSuffix: true) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &__image-container {
        border-radius: 5px;
        background: $color-grey-lighter;
        margin: 15px 15px 0;
        border-radius: 5px;
        background: $color-grey-lighter;
        background-size: cover;
        background-repeat: no-repeat;

        @include screen--md-up($withoutSuffix: true) {
            margin: 15px 30px 15px 15px;
        }

        &--blog-small {
            grid-area: 1 / 1 / 3 / 2;


            @include screen--md-up($withoutSuffix: true) {
                grid-area: 1 / 1 / 6 / 2;
                margin: 15px 0px 15px 15px;
            }
        }

        &--blog-large {
            grid-area: 1 / 1 / 6 / 3;
            height: 125px;

            @include screen--md-up($withoutSuffix: true) {
                grid-area: 1 / 1 / 6 / 2;
                height: auto;
            }
        }

        &--frontpage {
            grid-area: 1 / 1 / 3 / 2;

            @include screen--md-up($withoutSuffix: true) {
                grid-area: 1 / 1 / 6 / 2;
            }
        }
    }

    &__image {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        object-fit: cover;
    }

    &__title {
        font-weight: $font-weight-semi-bold;
        color: $color-black;
        padding-top: 20px;
        font-size: 18px;
        line-height: 24px;

        @include screen--md-up($withoutSuffix: true) {
            grid-area: 1 / 4 / 2 / 2;
            padding-top: 25px;
        }

        &--blog-small {
            grid-area: 1 / 2 / 3 / 6;
            margin-right: 15px;

            @include screen--md-up($withoutSuffix: true) {
                padding-top: 25px;
                margin: 0 0 0 30px;
            }
        }

        &--blog-large {
            grid-area: 1 / 3 / 3 / 6;
            padding-top: 19px;
            padding: 19px 15px 0 0;
            overflow: hidden;

            @include screen--md-up($withoutSuffix: true) {
                grid-area: 1 / 2 / 3 / 6;
                margin: 0 10px 0 0;
            }
        }

        &--frontpage {
            grid-area: 1 / 2 / 3 / 6;
            font-size: 24px;
        }

        &--large {
            @include screen--md-up($withoutSuffix: true) {
                font-size: 24px;
                line-height: 26px;
            }
        }

        &--small {
            @include screen--md-up($withoutSuffix: true) {
                font-size: 18px;
                line-height: 24px;
            }
        }
    }

    &__text {
        color: $color-grey-light;
        font-size: 14px;
        line-height: 20px;
        grid-area: 3 / 1 / 6 / 6;
        overflow: hidden;
        margin: 15px 15px;
        line-height: 20px;

        @include screen--md-up($withoutSuffix: true) {
            grid-area: 2 / 2 / 6 / 6;
            padding-top: 10px;
            margin: 10px 50px 30px 0;
        }

        &--blog-large {
            grid-area: 3 / 3 / 6 / 6;
            margin: 0 15px 15px 0;

            @include screen--md-up($withoutSuffix: true) {
                grid-area: 2 / 2 / 6 / 6;
                margin: 0 50px 0 0;
            }
        }

        &--large {
            @include screen--md-up($withoutSuffix: true) {
                line-height: 24px;
                font-size: 16px;
            }
        }

        &--small {
            @include screen--md-up($withoutSuffix: true) {
                font-size: 14px;
                line-height: 22px;
                margin: 15px 50px 30px 30px;
            }
        }
    }
}
