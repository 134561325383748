﻿@import '../variables/colors';
@import '../variables/fonts';
@import '../globals/media-queries';

.pagination {
    display: block;
    $self: &;
    margin: 0 auto;
    width: fit-content;

    &__link {
        color: $color-grey-light;
        float: left;
        padding: 9px 11px;
        text-decoration: none;
        background-color: $color-grey-white;
        border-radius: 5px;
        margin: 0 5px;
        transition: color 0.3s ease;
        font-family: $font-primary;
        font-size: 12px;

        @include screen--md-up($withoutSuffix: true) {
            padding: 13px 18px;
            font-size: 14px;
            margin: 0 7px;
        }

        &:hover {
            color: $color-white;
            background-color: $color-pink;
        }

        &--prev {
            margin-right: 33px;
            padding: 9px 20px;

            @include screen--md-up($withoutSuffix: true) {
                margin-right: 40px;
                padding: 13px 20px;
            }
        }

        &--next {
            margin-left: 33px;
            padding: 9px 20px;

            @include screen--md-up($withoutSuffix: true) {
                margin-left: 40px;
                padding: 13px 20px;
            }
        }
    }

    .active {
        color: $color-white;
        background-color: $color-pink;
    }

    &--dark {
        #{ $self }__link {
            background-color: $color-black-lighter;
            color: $color-grey-white;

            &--active {
                color: $color-white;
                background-color: $color-pink;
            }
        }
    }
}

// Instantsearch pagination container
.ais-Pagination {
    min-height: 70px;

    @include screen--md-up($withoutSuffix: true) {
        margin-top: 50px;
        min-height: 140px;
    }
}

.catalog-description {
    font-size: 18px;
    max-width: 840px;
}