﻿@import "../globals/media-queries";
@import "../variables/colors";
@import "../variables/fonts";
@import "../variables/z-index";
@import "../variables/breakpoints";
@import "content-area";

.content-block {
    font-family: $font-primary;
    color: $color-black;
    background: $color-white;
    min-height: 100%;
    width: 100%;
    position: relative;
    margin-top: -1px;
    z-index: 0;
    margin-top: -1px;

    @include screen--md-up($withoutSuffix: true) {
        margin: 0 calc(50% - 50vw);
        width: 100vw;
        border-radius: 0;
    }

    &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        background: url('./gfx/dots-pattern.png');
        z-index: $z-index-background-elements;
    }

    &__container {
        padding: 3rem 1rem;
        max-width: $breakpoint-large;
        margin: 0 auto;

        &--slim {
            max-width: $breakpoint-content-width;
            line-height: 24px;
        }

        &--no-side-padding {
            padding: 1rem 0;
        }

        &--404 {
            display: block;

            @include screen--md-up($withoutSuffix: true) {
                display: flex;
            }

            img {
                margin-bottom: 1rem;
                box-shadow: 0px 10px 15px 0px rgba(0,0,0,0.09);

                @include screen--md-up($withoutSuffix: true) {
                    margin-right: 1rem;
                }
            }

            h1 {
                margin-bottom: 1rem;
            }
        }
    }

    &__content {
        margin: 1rem auto;
        line-height: 1.4;
    }

    &--frontpage {
        overflow: hidden;
    }

    .umb-grid {
        a {
            color: $color-pink;
        }

        a:hover {
            color: $color-pink-light;
        }

        i {
            font-style: italic;
        }

        b, strong {
            font-weight: bolder;
        }

        u {
            text-decoration: underline;
        }

        p {
            margin: 1rem 0;
        }

        img {
            display: inline-block;
            max-width: 100%;
        }
    }
}

.content-divider {
    width: 100%;

    &__hr {
        border: 0;
        border-top: 1px solid #DEDEDE;
    }
}

.container {
    max-width: $breakpoint-large;
    padding: 0;
}

.content-wrapper {
    max-width: $breakpoint-large;
    margin: 0 auto;
    position: relative;
}
