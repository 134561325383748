@import '../variables/colors';
@import '../variables/fonts';
@import '../variables/breakpoints';
@import '../globals/media-queries';

.label {
    padding: 7px 11px;
    display: block;
    border-radius: 5px 0;
    font-size: 12px;
    font-weight: $font-weight-semi-bold;
    background-color: $color-yellow;
    position: relative;
    transition: background 0.5s ease;
    color: $color-black;
    margin: 0 0 0.5rem;
    width: fit-content;

    &__container {
        position: absolute;
        top: 0;
        left: 0;
    }

    &:hover {
        background: $color-yellow-light;
        cursor: pointer;
    }

    @include screen--md-up($withoutSuffix: true) {
        font-size: 14px;
    }

    &--alternative {
        background-color: $color-blue;
        transition: background 0.5s ease;

        &:hover {
            background: $color-blue-light;
        }
    }
}