﻿@import "../variables/colors";
@import "../buttons/buttons";
@import "../variables/fonts";
@import "../variables/index";
@import "../globals/media-queries";


$gutter: 10px;

//standard calculation for the layout grid
@for $column from 1 through 12 {
    $blocks-per-row: 12 / $column;
    $gaps-per-column: $blocks-per-row - 1;
    $gutter-per-column: $gaps-per-column * $gutter / $blocks-per-row;
    $width-per-column: $column / 12 * 100%;

    .formulate__cell--#{$column}-columns {
        width: calc(#{$width-per-column} - #{$gutter-per-column});
    }
}

//needed to be tweeked a bit
.formulate__cell--6-columns {
    width: 100%;

    @include screen--md-up($withoutSuffix: true) {
        width: calc(47.8% - 5px);
    }
}

//entire form
.formulate-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;

    //label can be modified if needed but the default was way off grid 
    //so for now it has been removed
    label {
        display: none;
    }
}

//formulate creates these automatically for ever row
.formulate__row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    textarea {
        overflow-y: hidden;
        width: 100%;
        background: $color-white;
        border: 1px solid $color-black-lightest;
        border-radius: 5px;
        height: 100px;
        margin: 1rem auto;
        color: $color-black-lightest;
        font-family: $font-primary;
        padding: 1rem;
        box-sizing: border-box;
    }

    input {
        background: $color-white;
        border: 1px solid $color-black-lightest;
        border-radius: 5px;
        height: 43px;
        margin: 1rem auto;
        color: $color-black-lightest;
        font-family: $font-primary;
        padding: 0 1rem;
        width: 100%;
        box-sizing: border-box;
    }

    button {
        @include standard-button-style();
        border-radius: 100px;
        padding: 5px 55px;
        font-style: normal;
        font-weight: $font-weight-bold;
        font-size: 16px;
        margin: 1rem auto;
        line-height: 30px;
        text-align: center;
        color: $color-white;
        border: none;
        text-decoration: none;
        background-color: $color-green;

        @include screen--md-up($withoutSuffix: true) {
            padding: 10px 55px;
        }

        &:hover {
            background-color: $color-green-light;
        }
    }
}

.formulate__form {
    flex-direction: column;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    width: 70%;
}

.formulate__field--button {
    display: flex;
}

.form-success {
    color: $color-green;
    margin: auto;
    text-align: center;
    padding: 4rem 0 3rem;
}

.form-headline {
    text-align: center;
    margin: auto;
    padding: 4rem 0 1rem;
}
