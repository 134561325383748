﻿@import '../variables/colors';
@import '../variables/fonts';
@import '../globals/media-queries';

$carousel-dot-size: 5px;

.carousel-container {
    min-height: 480px;

    @include screen--md-up($withoutSuffix: true) {
        min-height: 550px;
    }

    &--product-carousel {
        position: relative;
    }
}

.toggled-content {
    position: absolute;
    width: 100%;
}

.carousel {
    width: 100%;

    @include screen--md-up($withoutSuffix: true) {
        margin: 0 auto 2rem;
    }

    &__title {
        font-size: 24px;
        line-height: 34px;
        font-weight: $font-weight-semi-bold;
        color: $color-black;
        margin-top: 39px;
        padding-bottom: 30px;

        @include screen--lg-up($withoutSuffix: true) {
            font-size: 32px;
            margin: 50px 0 30px;
        }
    }

    .flickity-page-dots .dot {
        width: $carousel-dot-size;
        height: $carousel-dot-size;
        opacity: 1;
        background: transparent;
        border: 2px solid $color-black;
        margin: 0 3px;
    }

    .flickity-page-dots .dot.is-selected {
        background: $color-black;
    }

    .flickity-button {
        background: transparent;
    }

    .flickity-button:disabled {
        opacity: 0;
    }

    .flickity-button-icon {
        fill: none;
        background: transparent;
    }

    .previous {
        background: url('./gfx/arrow-button-left.svg') no-repeat;
        left: -80px;
    }

    .next {
        background: url('./gfx/arrow-button-right.svg') no-repeat;
        right: -80px;
    }

    .flickity-prev-next-button {
        display: none;

        @include screen--md-up($withoutSuffix: true) {
            display: block;
            border-radius: 0;
        }
    }
}

.product-carousel {
    max-width: 1070px;
    padding: 0 0 1rem;

    @include screen--md-up($withoutSuffix: true) {
        margin: 0 auto 2rem;
    }

    &--custom {
        padding-bottom: 2.5rem;
    }
}

.genres-carousel {
    width: 100%;
    background: $color-black-light;

    @include screen--md-up($withoutSuffix: true) {
        margin: 0 calc(50% - 50vw);
        width: 100vw;
        border-radius: 0;
    }

    &__container {
        display: flex;
        height: auto;
        flex-direction: column;
        padding: 1.5rem 0;
        align-items: center;
        max-width: $breakpoint-large;
        margin: 0 auto;

        @include screen--md-up($withoutSuffix: true) {
            align-items: flex-start;
            padding: 3rem 0;
            flex-direction: row;
        }
    }

    &__left {
        height: 100%;
        width: 100%;
        text-align: center;

        @include screen--md-up($withoutSuffix: true) {
            width: 20%;
            text-align: left;
            align-items: flex-start;
            margin-left: 2%;
        }
    }

    &__right {
        width: 100%;
        height: 100%;
        position: relative;

        @include screen--md-up($withoutSuffix: true) {
            width: 75%;
            margin-left: 5%;
            align-self: center;
        }
    }

    &__left-text {
        color: $color-white;
        text-align: center;
        font-weight: $font-weight-semi-bold;
        font-size: 18px;
        line-height: 54px;
        padding: 0;

        &:before {
            content: '';
            width: 33px;
            height: 25px;
            display: inline-block;
            background: url('./gfx/book.svg') no-repeat;
            position: relative;
            top: 4px;
            right: 10px;
        }

        @include screen--md-up($withoutSuffix: true) {
            padding: 1rem;
            font-size: 24px;
            line-height: 44px;
        }
    }

    &__left-paragraph {
        color: $color-grey-light;
        font-size: 14px;
        line-height: 22px;

        @include screen--md-up($withoutSuffix: true) {
            margin-top: 10px;
        }
    }

    &__list-container {
        margin: auto;
        width: 90%;
        padding-top: 1.2rem;

        @include screen--md-up($withoutSuffix: true) {
            width: 80%;
            padding-top: 0;
        }
    }

    .next,
    .previous {
        background: none;
    }

    .flickity-button-icon {
        fill: $color-grey-light;
    }

    .flickity-page-dots {
        display: none;
    }
}

.frontpage-carousel {
    width: 100%;
    display: flex;
    margin: auto;
    text-align: center;
    font-family: $font-primary;
    flex-direction: column;
    max-width: $breakpoint-large;
    margin-bottom: 10px;

    @include screen--md-up($withoutSuffix: true) {
    }

    .flickity-page-dots {
        display: none;
    }

    &__title {
        font-size: 32px;
        line-height: 34px;
        font-weight: $font-weight-semi-bold;
        color: $color-black;
        margin: 39px 0;

        @include screen--lg-up($withoutSuffix: true) {
            margin: 80px 0 50px 0;
        }
    }

    &__button {
        margin: auto;
        padding-bottom: 10px;
        margin-bottom: 50px;

        &:hover {
            background-color: $color-pink-light;
        }
    }

    &__button-text {
        font-size: 14px;
        line-height: 16px;
        color: $color-white;
        font-weight: $font-weight-semi-bold;

        &:after {
            content: '';
            width: 22px;
            height: 19px;
            display: inline-block;
            background: url('./gfx/arrow-forward.svg') no-repeat;
            position: relative;
            top: 10px;
            right: -13px;
        }
    }
}
