﻿@import "../variables/colors";
@import "../variables/fonts";
@import "../variables/index";
@import "../globals/media-queries";
@import "hamburger";
@import "submenu";

.navigation-bar {
    height: 125px;
    display: flex;
    justify-content: space-between;
    font-family: $font-primary;
    max-width: $breakpoint-large;
    margin: 0 auto;
    position: relative;
    background: linear-gradient(180deg, $color-black 0%, rgba(0, 0, 0, 0.8) 51.56%, rgba(0, 0, 0, 0) 100%);

    @include screen--md-up($withoutSuffix: true) {
        height: 70px;
        align-items: center;
        padding: 0 1rem;
    }

    &__wrapper {
        @include screen--md-up($withoutSuffix: true) {
            height: 70px;
            background-color: $color-black;
            width: 100%;
            position: relative;
            z-index: $z-index-menu-overlay;
        }
    }

    &__border {
        display: none;

        @include screen--md-up($withoutSuffix: true) {
            display: block;
            height: 3px;
            border: 0;
            background: linear-gradient(to left, $color-black, $color-grey-light, $color-black);
            margin: 0 auto;
            position: relative;
            z-index: $z-index-navigation-elements;
        }
    }

    &__logo {
        position: relative;
        top: 20px;
        left: 1rem;
        flex: 1;
        z-index: $z-index-logo;

        @include screen--md-up($withoutSuffix: true) {
            left: 0;
        }
    }

    &__links {
        display: flex;
        flex: 3;
        justify-content: center;
    }

    &__link {
        display: none;

        &:hover,
        &:active {
            color: $color-black;
            text-decoration: none;
            background-image: url('./gfx/label-shape_top.svg'), url('./gfx/label-shape_bottom.svg'), linear-gradient(to bottom, #000, #fff 10%, #fff 95%, #000 10%);
            background-position: top, bottom, center;
            background-repeat: no-repeat;
            background-size: 100%, 100%;
        }

        @include screen--md-up($withoutSuffix: true) {
            display: inline-block;
            text-decoration: none;
            color: $color-white;
            font-size: 14px;
            padding: 0.5rem 1rem;
            font-weight: 600;
            margin: 0 0.5rem;
        }
    }

    &__hamburger {
        display: inline-block;

        @include screen--md-up($withoutSuffix: true) {
            display: none;
        }
    }

    &__search {
        display: flex;
        align-items: start;
        padding: 0.95rem;
        cursor: pointer;
        z-index: $z-index-logo;
        margin-right: 1rem;
        position: relative;

        @include screen--md-up($withoutSuffix: true) {
            padding: 0;
        }

        svg {
            width: 19px;
            position: absolute;
            top: 14px;

            @include screen--md-up($withoutSuffix: true) {
                width: 22px;
                top: -9px;
                right: 52px;
            }
        }

        .svg-close {
            top: 22px;
            width: 15px;

            @include screen--md-up($withoutSuffix: true) {
                top: 2px;
                width: 18px;
                right: 56px;
            }
        }

        img {
            width: 25px;

            @include screen--md-up($withoutSuffix: true) {
                width: 20px;
                position: relative;
                top: -5px
            }
        }

        span {
            display: none;

            @include screen--md-up($withoutSuffix: true) {
                display: block;
            }
        }
    }

    &__right-panel {
        flex: 1;
        display: flex;
        float: right;
        position: relative;
        right: 0;
        top: 23px;
        z-index: $z-index-dropdown-content;

        @include screen--md-up($withoutSuffix: true) {
            top: 0;
            justify-content: flex-end;
        }
    }

    &__shop {
        display: none;
    }

    &__search,
    &__shop {
        @include screen--md-up($withoutSuffix: true) {
            display: flex;
            align-items: center;
            text-decoration: none;
            color: $color-white;
            font-size: 14px;
            margin: 0 0 0 2rem;
        }

        &:hover,
        &:active {
            color: $color-white;
            text-decoration: none;
        }

        span {
            margin: 0 0 0 0.3rem;
        }
    }
}