﻿@import '../variables/colors';
@import '../variables/fonts';
@import '../globals/media-queries';

@mixin standard-card-container-layout () {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

@mixin standard-card-small-layout () {
    width: 100%;
    height: 215px;

    @include screen--lg-up($withoutSuffix: true) {
        grid-template-columns: repeat(5, 1fr);
        margin: 10px 0;
        height: 180px;
        width: 630px;
        margin-bottom: 24px;
    }
}

@mixin standard-card-large-layout () {
    width: 345px;
    height: 155px;

    @include screen--md-up($withoutSuffix: true) {
        width: 100%;
        height: 260px;
        margin-bottom: 24px;
    }
}
