﻿@import "media-queries";
@import "../variables/colors";
@import "../variables/fonts";

.headline {
    &--h1 {
        font-family: $font-primary;
        font-size: 24px;
        line-height: 40px;
        margin-bottom: 1rem;
        font-weight: $font-weight-semi-bold;

        @include screen--md-up($withoutSuffix: true) {
            font-size: 54px;
            margin-bottom: 0.5rem;
            line-height: 68px;
        }
    }

    &--h1-large {
        font-family: $font-primary;
        font-weight: $font-weight-semi-bold;
        font-size: 24px;
        line-height: 34px;

        @include screen--md-up($withoutSuffix: true) {
            font-size: 74px;
            line-height: 80px;
        }
    }

    &--h1-centered {
        text-align: center;
    }

    @include screen--md-up($withoutSuffix: true) {
        &--h2-large {
            font-family: $font-primary;
            font-weight: $font-weight-semi-bold;
            font-size: 54px;
            line-height: 58px;
        }
    }

    &--h2-regular {
        font-family: $font-primary;
        font-weight: $font-weight-semi-bold;
        font-size: 24px;
        line-height: 26px;

        @include screen--md-up($withoutSuffix: true) {
            font-size: 32px;
            line-height: 34px;
        }
    }

    &--h3 {
        font-family: $font-primary;
        font-weight: $font-weight-semi-bold;
        font-size: 18px;
        line-height: 26px;

        @include screen--md-up($withoutSuffix: true) {
            font-size: 24px;
            line-height: 26px;
        }
    }

    &--h4 {
        font-family: $font-primary;
        font-weight: $font-weight-semi-bold;
        font-size: 16px;
        line-height: 24px;

        @include screen--md-up($withoutSuffix: true) {
            font-size: 18px;
            line-height: 26px;
        }
    }

    &--h5 {
        font-family: $font-primary;
        font-weight: $font-weight-semi-bold;
        font-size: 14px;
        line-height: 20px;

        @include screen--md-up($withoutSuffix: true) {
            font-size: 16px;
            line-height: 26px;
        }

        &-underlined {
            text-decoration: underline;
        }

        &-accent {
            color: $color-pink;
        }

        &-grey {
            color: $color-grey-lighter;
        }
    }
}

.body-text { 
    font-family: $font-primary;
    font-size: 14px;
    line-height: 20px;

    @include screen--md-up($withoutSuffix: true) {
        font-size: 16px;
        line-height: 24px;
    }

    &--small {
        font-size: 12px;
        line-height: 16px;

        @include screen--md-up($withoutSuffix: true) {
            font-size: 14px;
            line-height: 22px;
        }
    }

    &--large {
        font-size: 14px;
        line-height: 16px;

        @include screen--md-up($withoutSuffix: true) {
            font-size: 18px;
            line-height: 26px;
        }
    }

    &--underlined {
        text-decoration: underline;
    }

    &--semi-bold {
        font-weight: $font-weight-semi-bold;
    }

    &--bold {
        font-weight: $font-weight-bold;
    }

    &--black {
        color: $color-black;
    }
}

.main-title {
    margin-bottom: 24px;

    @include screen--md-up($withoutSuffix: true) {
        margin-bottom: 30px;
    }
}
