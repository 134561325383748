﻿@import '../variables/colors';
@import '../variables/fonts';
@import '../globals/media-queries';
@import '../globals/typography';

.special-gift-block {
    margin: 1.5rem 0;
    width: 100%;
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    background: $color-black-lighter;
    font-family: $font-primary;

    @include screen--md-up($withoutSuffix: true) {
        margin: 1.9rem 0;
    }

    &__left-container {
        flex: 10 0 0;
        flex-direction: row;
        flex-wrap: wrap;
        display: flex;
    }

    &__image-container {
        border-radius: 3px;
        margin: 10px 15px 10px 10px;
        background: $color-white;
        width: 45px;
        height: 64px;

        @include screen--md-up($withoutSuffix: true) {
            margin: 10px 15px;
        }
    }

    &__title {
        flex: 1;
        line-height: 20px;
        text-align: left;

        p {
            font-weight: $font-weight-regular;
            color: $color-grey-lighter;
            margin: 0.6rem 0 0.25rem;
        }
    }

    &__right-container {
        flex: 1 0 0;
    }

    &__more-info-content {
        width: 100%;
        background: $color-black-lighter;
        margin: 0px 15px;
        border-top: 0px;
        font-size: 14px;
        line-height: 20px;
        text-align: left;
        height: 0;
        overflow-y: hidden;
        transition: 0.3s ease;

        p {
            margin-top: 15px;
            font-weight: $font-weight-semi-bold;
            color: $color-white;
        }

        ul {
            list-style: none;
            padding: 0 0 24px 0px;
        }

        li {
            font-weight: $font-weight-regular;
            color: $color-grey-lighter;
            padding-top: 14px;

            &:before {
                content: '■';
                color: $color-white;
            }
        }
    }

    &__text {
        position: relative;
        left: 10px;

        &--zoom {
            cursor: pointer;
        }
    }

    &__item-zoom {
        display: flex;
        align-items: center;
        font-weight: $font-weight-semi-bold;
        font-size: 14px;
        line-height: 20px;
        color: $color-pink;
        text-decoration: none;
        padding-bottom: 24px;
    }

    &__item-zoom-image {
        width: 11px;
        height: 11px;
    }

    button {
        margin: 27px 15px;
    }
}

.reveal--open {
    .read-more-block__button {
        transform: rotate(180deg);
    }

    .special-gift-block__more-info-content {
        border-top: 1px solid $color-black-lightest;
    }
}
