﻿@import '../variables/colors';
@import '../variables/fonts';
@import '../globals/media-queries';
@import '../popups/mobile-popup-mixin';

.bottom-social-popup {
    @include standard-mobile-bottom-popup-style();

    @include screen--lg-up($withoutSuffix: true) {
        display: none;
    }

    &__text {
        text-decoration: none;
        color: $color-white;

        &:hover,
        &:active {
            color: $color-white;
            text-decoration: none;
        }

        &:before {
            content: '';
            width: 22px;
            height: 19px;
            display: inline-block;
            position: relative;
            top: 4px;
            right: 10px;
        }
    }

    &__twitter:before {
        background: url('./gfx/twitter.svg') no-repeat center;
    }

    &__facebook:before {
        background: url('./gfx/facebook.svg') no-repeat center;
    }

    &__pinterest:before {
        background: url('./gfx/pinterest.svg') no-repeat center;
    }

    &__link {
        width: 100%;
        text-align: left;
        margin-left: 15px;
        padding: 15px;
        cursor: pointer;

        &--border {
            border-bottom: 1px solid $color-black-lightest;
        }
    }
}
