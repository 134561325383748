﻿@import "media-queries";

.hide {
    display: none;
}

.hidden {
    visibility: hidden;
}

.hide-on-desktop {
    @include screen--md-up($withoutSuffix: true) {
        display: none;
    }
}
