﻿@import "../globals/media-queries";
@import "../variables/colors";
@import "../variables/fonts";
@import "../variables/z-index";
@import "../variables/breakpoints";

.catalog-header {
    height: 348px;
    position: relative;
    margin-top: -123px;

    @include screen--md-up($withoutSuffix: true) {
        height: 360px;
        margin-top: 0;
    }

    &__title {
        bottom: 20px;
        position: absolute;
        width: 100%;
        text-align: center;
        font-size: 18px;

        @include screen--md-up($withoutSuffix: true) {
            bottom: 30px;
            text-align: left;
            width: calc(100% - 2rem);
            font-size: 54px;
        }
    }

    &__subtitle {
        bottom: 50px;
        position: absolute;
        width: 100%;
        text-align: center;
        font-family: $font-primary;
        font-weight: $font-weight-semi-bold;
        font-size: 12px;
        line-height: 20px;

        @include screen--md-up($withoutSuffix: true) {
            text-align: left;
            width: calc(100% - 2rem);
            font-size: 16px;
        }
    }
}

.catalog-filters {
    min-height: 2rem;
    background: $color-black-lighter;
    position: relative;
    top: 0;
    padding: 0.5rem 0;
    z-index: $z-index-navigation-elements;

    @include screen--md-up($withoutSuffix: true) {
        margin: 0 calc(50% - 50vw);
        width: 100vw;
        top: unset;
        z-index: unset;
    }

    &__container {
        display: none;
        max-width: $breakpoint-large;
        margin: 0 auto;
        padding: 1rem;
        flex-flow: column;
        justify-items: center;

        @include screen--md-up($withoutSuffix: true) {
            display: block;
            padding: 1rem;
        }
    }

    &__mangafinder {
        display: block;
        max-width: $breakpoint-large;
        margin: 0.5rem auto;
        width: 80%;
        font-size: 12px;

        @include screen--md-up($withoutSuffix: true) {
            display: none;
        }

        &:before {
            content: '';
            display: inline-block;
            height: 15px;
            width: 20px;
            margin: 0 0.5rem 0 0;
            background: url('./gfx/manga-finder.svg') no-repeat center;
            position: relative;
            top: 4px;
        }
    }

    &__block-group {
        @include screen--md-up($withoutSuffix: true) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
            grid-column-gap: 0px;
            grid-row-gap: 0px;
        }
    }

    &__block {
        width: 100%;
        position: relative;

        &:nth-child(1) {
            grid-area: 1 / 1 / 2 / 2;
        }

        &:nth-child(2) {
            grid-area: 1 / 2 / 2 / 3;
        }

        &:nth-child(3) {
            grid-area: 2 / 1 / 3 / 2;
        }

        &:nth-child(4) {
            grid-area: 2 / 2 / 3 / 3;
        }

        &--margin-bottom {
            margin-bottom: 40px;

            @include screen--md-up($withoutSuffix: true) {
                margin-bottom: 0;
            }
        }

        &--margin-top {
            margin-top: 0px;

            @include screen--md-up($withoutSuffix: true) {
                margin-top: 15px;
            }
        }
    }

    &__done-button {
        margin: 4.5rem 1rem 0;

        @include screen--md-up($withoutSuffix: true) {
            margin: 1rem 1rem 0;
        }
    }

    hr {
        display: block;
        border-color: $color-black-lightest;
        border-style: solid;
        margin-top: 24px;
    }
}

.catalog-products {
    min-height: 260px;
    background: $color-black-light;

    @include screen--md-up($withoutSuffix: true) {
        margin: 0 calc(50% - 50vw);
        width: 100vw;
    }

    &__container {
        max-width: $breakpoint-large;
        margin: 0 auto;
    }
    /* Algolia related CSS */
    .ais-Hits-list {
        display: flex;
        flex-wrap: wrap;
        padding: 1rem 0;
        justify-content: center;
    }
}

.catalog-genre-title {
    margin-bottom: 10px;
    font-size: 18px;

    &--margin-top-bottom {
        margin: 20px 0;
    }
}

.genre-container {
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;

    @include screen--md-up($withoutSuffix: true) {
        justify-content: start;
    }
}