﻿@import "../variables/colors";
@import "../variables/fonts";
@import "../variables/index";
@import "../globals/media-queries";
@import '../popups/mobile-popup-mixin';

$shadow-weight-sides: 240px;
$shadow-weight-bottom: 400px;
$age-limit-size: 50px;
$age-limit-size-mobile: 30px;

.product-header {
    padding: 60px 1rem 0;
    display: flex;
    margin: 0 0 4rem;
    flex-direction: column;
    align-items: center;
    font-family: $font-primary;

    @include screen--sm-up($withoutSuffix: true) {
        padding: 110px 3rem 0;
    }

    @include screen--md-up($withoutSuffix: true) {
        padding: 310px 90px 0;
        flex-direction: row;
        align-items: start;
    }

    @include screen--lg-up($withoutSuffix: true) {
        padding: 310px 110px 0;
    }

    &__age-limit {
        position: absolute;
        height: $age-limit-size-mobile;
        width: $age-limit-size-mobile;
        top: calc(#{$age-limit-size-mobile} / 2 * -1);
        right: calc(#{$age-limit-size-mobile}/ 2 * -1);
        background: $color-pink;
        border-radius: 50%;
        font-size: 14px;
        font-weight: $font-weight-bold;
        text-align: center;
        line-height: $age-limit-size-mobile;

        @include screen--md-up($withoutSuffix: true) {
            height: $age-limit-size;
            width: $age-limit-size;
            top: calc(#{$age-limit-size} / 2 * -1);
            right: calc(#{$age-limit-size}/ 2 * -1);
            background: $color-pink;
            font-size: 20px;
            font-weight: $font-weight-semi-bold;
            line-height: 49px;
        }
    }

    &__cover-features {
        width: 100%;
        height: 30px;
        margin-top: -3px;
        display: flex;

        @include screen--md-up($withoutSuffix: true) {
            height: 45px;
        }
    }

    &__cover-features-button {
        flex: 1;
        text-align: center;
        cursor: pointer;

        &:first-child {
            border-bottom-left-radius: 5px;
        }

        &:last-child {
            border-bottom-right-radius: 5px;
        }
    }

    &__coverbild {
        border-right: 1px solid $color-black-lightest;
        padding-top: 8px;
    }

    &__tabs {
        margin-bottom: 20px;
    }

    &__features-text {
        @include screen--sm-up($withoutSuffix: true) {
            margin-top: 0.8rem;
            font-size: 12px;
            line-height: 22px;
            display: inline;
        }

        @include screen--md-up($withoutSuffix: true) {
            margin-top: 0.8rem;
            font-size: 14px;
            line-height: 20px;
        }

        &--custom {
            color: $color-grey-lighter;
            text-decoration: none;
        }
    }

    &__leseprobe {
        padding: 8px 0 0 3px;
        text-align: center;
    }

    &__cover {
        position: relative;
        width: 157px;
        box-shadow: -10px 0px 15px 0px rgba(0,0,0,0.6);
        margin-bottom: 20px;

        @include screen--md-up($withoutSuffix: true) {
            width: 220px;
            box-shadow: none;
            flex-grow: 0;
            z-index: $z-index-pop-up-overlay;
            margin-bottom: 0px;
            flex-shrink: 0;
        }

        @include screen--lg-up($withoutSuffix: true) {
            width: 303px;
        }

        img {
            width: 100%;
            border-radius: 5px;

            &:hover {
                cursor: zoom-in;
            }
        }
    }

    &__link {
        color: $color-grey-lightest;
    }

    &__ecommerce {
        @include screen--md-up($withoutSuffix: true) {
            margin: 1.5rem auto;
        }

        &--cta {
            margin-bottom: 0rem;
        }
    }

    &__content {
        position: relative;
        flex: 3;
        margin: 2rem auto;
        text-align: center;

        @include screen--sm-only($withoutSuffix: true) {
            margin: 0;
            width: 100%;
        }

        @include screen--md-up($withoutSuffix: true) {
            margin: 0 0 0 2rem;
            text-align: left;
        }

        @include screen--lg-up($withoutSuffix: true) {
            margin: -1rem 0 0 7rem;
        }
    }

    &__sub-headline {
        padding-bottom: 0.5rem;
        margin: -1rem 0 2rem 0;
        color: $color-grey-lightest;

        @include screen--md-up($withoutSuffix: true) {
            margin: 0;
        }
    }
}

.features-button-title {
    display: none;

    @include screen--md-up($withoutSuffix: true) {
        display: inline;
    }
}

.feature-icon {
    &:before {
        content: '';
        width: 22px;
        height: 19px;
        display: inline-block;
        position: relative;
        top: -1px;

        @include screen--md-up($withoutSuffix: true) {
            margin-right: 10px;
            top: 4px;
        }
    }

    &:after {
        border-bottom: 1px solid $color-black-lightest;
    }


    &--coverbild:before {
        background: url('./gfx/zoom.svg') no-repeat center;

        @include screen--md-up($withoutSuffix: true) {
            width: 13px;
        }
    }

    &--coverbild-dropdown:before {
        background: url('./gfx/zoom.svg') no-repeat center;
        top: 4px;
        margin: 0 0.5rem 0 0;

        @include screen--md-up($withoutSuffix: true) {
            width: 30px;
            margin-right: 10px;
        }
    }

    &--leseprobe:before {
        background: url('./gfx/read.svg') no-repeat center;

        @include screen--md-up($withoutSuffix: true) {
            width: 20px;
        }
    }

    &--download:before {
        background: url('./gfx/download.svg') no-repeat center;
        background-size: contain;
        width: 30px;
        top: 2px;
        margin: 0 0.5rem 0 0;

        @include screen--md-up($withoutSuffix: true) {
            margin-right: 10px;
        }
    }
}

.cover-button-focus {
    background: $color-black-lightest;
    transition: color 0.5s ease-in-out;
    color: $color-white;
}