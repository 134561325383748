﻿@import '../variables/colors';
@import '../variables/fonts';
@import '../globals/media-queries';

@mixin standard-button-style () {
    font-family: $font-primary;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s ease;
    white-space: nowrap;

    &:focus {
        outline: none;
    }
}

.frontpage-button {
    margin: 0 auto 80px;
}

.no-results-button {
    margin: 0 auto;
    display: block;
    width: 200px;
}

.submit-button {
    @include standard-button-style();
    border-radius: 100px;
    padding: 5px 55px;
    font-style: normal;
    font-weight: $font-weight-bold;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    color: $color-white;
    border: none;
    text-decoration: none;

    @include screen--md-up($withoutSuffix: true) {
        padding: 10px 55px;
    }

    &--green {
        background-color: $color-green;

        &:hover {
            background-color: $color-green-light;
        }
    }

    &--pink {
        background-color: $color-pink;

        &:hover {
            background-color: $color-pink-light;
        }
    }

    &--regular-pink {
        background-color: $color-pink;
        padding: 10px 15px 10px;
        border-radius: 5px;
        color: $color-white;
        font-weight: $font-weight-semi-bold;
        transition: background 0.5s ease;
        text-decoration: none;
        font-size: 14px;
        line-height: 16px;
        color: $color-white;
        font-weight: $font-weight-semi-bold;
        width: max-content;

        &:hover {
            background-color: $color-pink-light;
        }
    }
}

.button {
    @include standard-button-style();
    border-radius: 5px;
    background-color: $color-black;
    text-align: center;
    border: none;
    font-style: normal;
    font-weight: $font-weight-semi-bold;
    font-size: 14px;
    line-height: 30px;
    height: 30px;
    border-radius: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    padding: 0 24px;
    margin: 12px 24px 12px 0;
    position: relative;

    &--large {
        background: $color-black-lightest;
        width: 194px;
        color: $color-grey-lighter;

        &:hover {
            background-color: $color-grey;
            color: $color-white;
        }

        &:active {
            background: $color-pink;
            color: $color-white;
        }
    }

    &--yellow {
        background-color: $color-yellow;
        color: $color-black;

        &:hover {
            background-color: $color-yellow-light;
            color: $color-black;
        }
    }

    &--small {
        background: $color-black-lightest;
        min-width: 115px;
        color: $color-grey-lighter;
        font-size: 12px;
        margin: 15px 15px 0 0;
        padding: 0 15px;

        &:hover {
            background: $color-grey;
            color: $color-white;
        }

        @include screen--md-up($withoutSuffix: true) {
            font-size: 14px;
            margin-right: 24px;
            min-width: 175px;
        }

        &--genre {
            padding: 0 25px;
            margin: 7px 0;

            @include screen--md-up($withoutSuffix: true) {
                margin: 12px;
            }
        }
    }

    &--auto-width {
        min-width: 0;
    }

    &--blue {
        background: $color-blue;
        color: $color-grey-white;
    }

    &--dropdown:after {
        content: '';
        display: block;
        background: url('./gfx/arrow-down.svg') no-repeat;
        position: absolute;
        width: 10px;
        height: 10px;
        right: 10px;
        top: 14px;
    }

    &--active {
        background: $color-pink;
        color: $color-white;

        &:hover {
            background: $color-pink-light;
            color: $color-white;
        }
    }

    &--transparent {
        background-color: transparent;
        border: 1px solid $color-black-lightest;
        box-sizing: border-box;
        font-weight: $font-weight-semi-bold;
        font-size: 12px;
        line-height: 22px;
        margin: 0 12px 0 0;

        @include screen--md-up($withoutSuffix: true) {
            font-size: 14px;
        }

        a {
            color: $color-grey-lighter;
            text-decoration: none;

            &:hover {
                text-decoration: none;
            }
        }

        &:hover {
            background: $color-black-lightest;
        }

        &--carousel {
            font-size: 12px;
            margin: 0 7px;
            padding: 10px 30px 30px;
            color: $color-grey-lighter;

            @include screen--md-up($withoutSuffix: true) {
                font-size: 16px;
                padding: 30px 30px 50px;
                min-width: 194px;
            }
        }
    }

    &--grey {
        background: $color-black-lighter;
        color: $color-grey-lighter;

        &:hover {
            transition: background 0.5s ease-in-out;
            background: $color-black-lightest;

            a {
                transition: color 0.5s ease-in-out;
                color: $color-white;
            }
        }
    }
}
