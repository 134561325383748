﻿@import '../variables/colors';
@import '../variables/fonts';
@import '../globals/media-queries';

.radio-button {
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 76%;

    @include screen--sm-up($withoutSuffix: true) {
        border-bottom: none;
        justify-content: start;
        height: auto;
    }

    &__container {
        position: relative;
        cursor: pointer;
        line-height: 20px;
        margin: auto;
        color: $color-white;
        font-weight: $font-weight-regular;
        font-size: 16px;
        line-height: 22px;
        display: flex;
        width: 50%;
        justify-content: center;

        input[type="radio"] {
            display: none;
        }

        @include screen--sm-up($withoutSuffix: true) {
            width: auto;
            margin-left: 0;
        }

        &--right {
            margin-left: 1rem;
        }
    }

    &__inner-cirlce {
        position: relative;
        display: block;
        float: left;
        margin-right: 10px;
        width: 20px;
        height: 20px;
        border: 2px solid $color-white;
        border-radius: 100%;
        -webkit-tap-highlight-color: transparent;
        line-height: 2.5rem;

        &:after {
            content: '';
            position: absolute;
            top: 2px;
            left: 2px;
            width: 16px;
            height: 16px;
            border-radius: 100%;
            background: $color-green;
            transform: scale(0);
            transition: all .2s ease;
            opacity: .08;
            pointer-events: none;
        }
    }

    input[type="radio"]:checked + .radio-button__inner-cirlce {
        border-color: $color-green;

        &:after {
            transform: scale(1);
            transition: all .2s cubic-bezier(.35,.9,.4,.9);
            opacity: 1;
        }
    }
}

$toggle-height: 50px;
$toggle-selector-padding: 6px;

.toggle-button {
    margin: -2rem auto 0;
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 14px;

    @include screen--md-up($withoutSuffix: true) {
        width: 100%;
        margin-bottom: 90px;
    }

    &__container {
        position: relative;
        display: flex;
        padding: 0 8px;
        background: $color-white;
        z-index: 0;
        margin: 0 auto 40px;
        line-height: $toggle-height;
        border-radius: calc(#{$toggle-height} / 2);
        height: $toggle-height;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.09), 0px 2px 6px rgba(0, 0, 0, 0.09), 0px 0px 1px rgba(0, 0, 0, 0.09);
        cursor: pointer;

        @include screen--md-up($withoutSuffix: true) {
            padding: 0 8px;
            margin: 0 auto;
        }
    }

    &__option-1,
    &__option-2 {
        padding: 0 1rem;

        @include screen--md-up($withoutSuffix: true) {
            padding: 0 2rem;
        }
    }

    .active {
        color: $color-white;
    }

    &__selector {
        background: $color-black;
        height: calc(#{$toggle-height} - (#{$toggle-selector-padding} * 2));
        border-radius: calc(#{$toggle-height} / 2);
        position: absolute;
        z-index: -1;
        top: $toggle-selector-padding;
        transition: 0.3s left cubic-bezier(0.69, 0.01, 0.56, 1.22), 0.3s width linear;
    }
}