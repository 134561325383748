﻿@import "../variables/colors";
@import "../variables/fonts";
@import "../variables/index";
@import "../variables/z-index";
@import "../globals/media-queries";

.hamburger-menu {
    display: block;
    z-index: $z-index-menu-overlay;
    -webkit-user-select: none;
    user-select: none;
    padding: 1.5rem;
    cursor: pointer;
    $self: &;

    &__line {
        display: block;
        width: 19px;
        height: 2px;
        margin-bottom: 4px;
        position: relative;
        background: $color-white;
        border-radius: 1px;
        z-index: 1;
        transform-origin: 0px 0px;
        transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0), background 0.5s cubic-bezier(0.77,0.2,0.05,1.0), opacity 0.55s ease;

        &:first-child {
            transform-origin: 100% 100%;
        }

        &:nth-last-child(3) {
            transform-origin: 0% 0%;
        }
    }

    &__header {
        background: $color-black;
        padding: 2rem 1rem 1rem;
        font-size: 24px;
        font-weight: $font-weight-semi-bold;

        &--sub:before {
            content: '';
            background: url('./gfx/arrow-back.svg') no-repeat center;
            width: 16px;
            height: 16px;
            background-size: 100%;
            display: inline-block;
            margin: 0 1rem 0 0;
        }
    }

    &__item {
        display: block;
        color: $color-grey-lighter;
        text-decoration: none;
        background: $color-black-lighter;
        padding: 1rem;
        font-size: 14px;
        font-weight: $font-weight-regular;
        border-bottom: 1px solid $color-black-lightest;

        &:first-child {
            border-top: 1px solid $color-black-lightest;
        }

        &--parent:after {
            content: '';
            background: url('./gfx/arrow-down.svg') no-repeat center;
            float: right;
            position: relative;
            width: 8px;
            height: 8px;
            top: 5px;
            transform: rotate(-90deg);
            transition: 0.3s all;
            background-size: 100%;
        }

        &--shop:after {
            content: '';
            background: url('./gfx/share.svg') no-repeat center;
            float: right;
            width: 15px;
            height: 15px;
            background-size: 100%;
        }
    }

    &__overlay {
        position: absolute;
        top: 0;
        width: 100vw;
        background: $color-black;
        list-style-type: none;
        -webkit-font-smoothing: antialiased;
        transform-origin: 0% 0%;
        transform: translate(0, 0);
        transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
        right: 0;
        min-height: calc(100vh - 100px);
        visibility: hidden;
        overflow-x: hidden;
        height: 100%;
        padding: 100px 0 0;
    }

    &__main-layer {
        transform: translate(0, 0);
        transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
    }

    &__sub-layer {
        position: absolute;
        top: 0;
        width: 100%;
        background: $color-black;
        -webkit-font-smoothing: antialiased;
        transform: translate(100%, 0);
        transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
        visibility: hidden;
    }

    &--active {
        #{ $self }__overlay {
            visibility: visible;
        }

        #{ $self }__line {
            opacity: 1;
            transform: rotate(-45deg);
            background: $color-white;

            &:nth-last-child(2) {
                opacity: 0;
                transform: rotate(0deg) scale(0.2, 0.2);
            }

            &:nth-last-child(3) {
                transform: rotate(45deg);
                left: 1px;
            }
        }
    }

    &--submenu-active {
        #{ $self }__main-layer {
            transform: translate(-100%, 0);
        }

        #{ $self }__sub-layer--active {
            visibility: visible;
        }
    }
}
