﻿@import '../variables/colors';
@import '../variables/fonts';
@import '../variables/z-index';
@import '../globals/media-queries';

$shadow-weight-sides-hero: 412px;
$shadow-weight-sides-product: 240px;
$shadow-weight-bottom: 400px;

.gradient {
    width: 100%;
    position: absolute;
    top: 0;
    background-repeat: no-repeat;
    background-position-y: 27%;
    background-position-x: center;
    flex: 0;
    opacity: 0.7;
    pointer-events: none;
    z-index: $z-index-background-elements;

    &--product {
        min-height: 550px;
        background-size: 160%;
        top: -7rem;

        @include screen--sm-up($withoutSuffix: true) {
            min-height: 530px;
        }

        @include screen--md-up($withoutSuffix: true) {
            min-height: 70%;
            background-size: 83%;
        }
    }

    &--blog {
        background-size: 100%;
        height: 100%;
        opacity: 1;
    }

    &--hero-slider {
        height: 100%;
        background-size: cover;
        background-position: center;
        z-index: 1;
        opacity: 1;
    }

    &--series {
        background-size: 160%;
        height: 100%;
        background-position-y: -50%;

        @include screen--sm-up($withoutSuffix: true) {
            background-position-y: 0%;
        }

        @include screen--md-up($withoutSuffix: true) {
            opacity: 1;
            background-size: 130%;
        }
    }

    &--catalog {
        opacity: 0.6;
        background-size: 160%;
        height: 100%;

        @include screen--md-up($withoutSuffix: true) {
            opacity: 1;
            background-size: 130%;
        }
    }
}

.shadow {
    background: linear-gradient(0deg, $color-black 0%, transparent 100%);
    display: block;
    position: absolute;
    width: 100%;
    height: $shadow-weight-bottom;
    bottom: 0;

    &--right {
        pointer-events: none;
        background: linear-gradient(-180deg, $color-black 0%, transparent 100%);
        top: 0;
        width: 100%;
        height: calc(#{$shadow-weight-bottom} / 2);

        @include screen--md-up($withoutSuffix: true) {
            background: linear-gradient(-90deg, $color-black 0%, transparent 100%);
            right: 0;
            height: 100%;

            &--product {
                width: $shadow-weight-sides-product;
                right: 7%;
            }

            &--hero-slider {
                width: $shadow-weight-sides-hero;
            }

            &--blog {
                width: 50%;
            }
        }
    }

    &--left {
        display: none;
        pointer-events: none;

        @include screen--md-up($withoutSuffix: true) {
            display: block;
            background: linear-gradient(90deg, $color-black 0%, transparent 100%);
            left: 0;
            height: 100%;

            &--product {
                width: $shadow-weight-sides-product;
                left: 7%;
            }

            &--hero-slider {
                width: $shadow-weight-sides-hero;
            }

            &--blog {
                width: 50%;
            }
        }
    }

    &--middle {
        opacity: 0.7;
    }

    &--middle-product {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.306053) 6.67%, rgba(0, 0, 0, 0.324857) 13.33%, rgba(0, 0, 0, 0.357162) 20%, rgba(0, 0, 0, 0.403188) 26.67%, rgba(0, 0, 0, 0.462242) 33.33%, rgba(0, 0, 0, 0.532319) 40%, rgba(0, 0, 0, 0.609884) 46.67%, rgba(0, 0, 0, 0.690116) 53.33%, rgba(0, 0, 0, 0.767681) 60%, rgba(0, 0, 0, 0.837758) 66.67%, rgba(0, 0, 0, 0.896812) 73.33%, rgba(0, 0, 0, 0.942838) 80%, rgba(0, 0, 0, 0.975143) 86.67%, rgba(0, 0, 0, 0.993947) 93.33%, #000000 100%);
        height: 100%;

        @include screen--md-up($withoutSuffix: true) {
            background: radial-gradient(79.61% 79.61% at 50% 0%, rgba(0, 0, 0, 0.14) 0%, rgba(0, 0, 0, 0.1472) 6.67%, rgba(0, 0, 0, 0.1695) 13.33%, rgba(0, 0, 0, 0.2078) 20%, rgba(0, 0, 0, 0.2624) 26.67%, rgba(0, 0, 0, 0.3324) 33.33%, rgba(0, 0, 0, 0.4155) 40%, rgba(0, 0, 0, 0.5074) 46.67%, rgba(0, 0, 0, 0.6026) 53.33%, rgba(0, 0, 0, 0.6945) 60%, rgba(0, 0, 0, 0.7776) 66.67%, rgba(0, 0, 0, 0.8476) 73.33%, rgba(0, 0, 0, 0.9022) 80%, rgba(0, 0, 0, 0.9405) 86.67%, rgba(0, 0, 0, 0.9628) 93.33%, rgba(0, 0, 0, 0.97) 100%);
        }
    }

    &__genre-carousel {
        width: 56px;
        height: 100%;
        bottom: 0;
        z-index: $z-index-carousel-content;

        @include screen--md-up($withoutSuffix: true) {
            width: 111px;
        }

        &--left {
            background: linear-gradient(90deg, $color-black-light 0%, transparent 100%);
            left: 0;
            pointer-events: none;

            @include screen--md-up($withoutSuffix: true) {
                left: 8%;
            }
        }

        &--right {
            background: linear-gradient(-90deg, $color-black-light 0%, transparent 100%);
            right: 0;
            pointer-events: none;

            @include screen--md-up($withoutSuffix: true) {
                right: 8%;
            }
        }
    }
}
