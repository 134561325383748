﻿@import '../variables/colors';
@import '../variables/fonts';
@import '../globals/media-queries';

.two-column-block {
    font-family: $font-primary;
    width: 100%;

    &__container {
        display: flex;

        @include screen--md-up($withoutSuffix: true) {
            padding-bottom: 42px;
        }
    }

    &__right {
        flex: auto;
        width: 30%;
    }

    &__left {
        display: flex;
        width: 70%;
    }

    ul {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
    }

    li {
        display: flex;
        align-items: center;
        margin: 10px 10px 0 0;

        @include screen--md-up($withoutSuffix: true) {
            margin: 10px 12px 0 0;
        }
    }
}
