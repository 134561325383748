﻿@import "../globals/media-queries";
@import "../variables/colors";
@import "../variables/breakpoints";

.content-area {
    color: $color-white;
    background: $color-black-light;
    min-height: 100%;
    width: 100%;
    position: relative;

    @include screen--md-up($withoutSuffix: true) {
        margin: 0 calc(50% - 50vw);
        width: 100vw;
        border-radius: 0;
    }

    &__container {
        max-width: $breakpoint-large;
        margin: 0 auto;
    }
}
