﻿@import "../variables/colors";
@import "../variables/fonts";
@import "../variables/index";
@import "../globals/media-queries";

.footer {
    width: 100%;
    min-height: 70px;
    background-color: $color-black-lighter;
    font-family: $font-primary;

    &__container {
        max-width: $breakpoint-large;
        margin: 0 auto;
        padding: 2rem 0 0;
    }

    &__top {
        display: flex;
        flex-direction: column;
        padding: 2rem 1rem;

        @include screen--md-up($withoutSuffix: true) {
            flex-direction: row;
        }
    }

    &__middle {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 1rem 3rem;

        @include screen--md-up($withoutSuffix: true) {
            padding: 2rem 1rem;
        }
    }

    &__bottom {
        background: $color-black-lightest;
        width: 100%;
        padding: 1.8rem 0 4.6rem;

        @include screen--md-up($withoutSuffix: true) {
            padding: 1.8rem 0 2.6rem;
        }
    }

    &__bottom-container {
        max-width: $breakpoint-large;
        margin: 0 auto;
        font-size: 14px;
        font-weight: $font-weight-regular;
        display: flex;
        flex-direction: column-reverse;
        padding: 0 1rem;

        @include screen--md-up($withoutSuffix: true) {
            display: block;
        }

        a {
            color: $color-pink;
        }
    }

    &__social {
        margin: 0 auto;
        width: 180px;
    }

    &__logo {
        padding: 0 1rem;
        opacity: 0.4;

        @include screen--md-up($withoutSuffix: true) {
            padding: 0 2rem;
        }

        img {
            width: 100%;
        }
    }

    &__community {
        flex: 1;
        display: flex;
        flex-direction: column;

        img {
            width: initial;
            display: block;
            margin: 0 auto;
        }
    }

    &__community-text {
        color: $color-pink;
        text-align: center;
        padding: 1rem;
        font-weight: $font-weight-semi-bold;
        font-size: 16px;

        @include screen--md-up($withoutSuffix: true) {
            font-size: 18px;
        }

        &:before {
            content: '';
            width: 22px;
            height: 19px;
            display: inline-block;
            background: url('./gfx/heart.svg') no-repeat;
            position: relative;
            top: 4px;
            right: 10px;
        }
    }

    &__copyright {
        text-align: center;
        width: 100%;
        margin: 2rem 0 0;

        @include screen--md-up($withoutSuffix: true) {
            float: left;
            width: auto;
            margin: 0;
        }
    }

    &__bottom-links {
        text-align: center;

        @include screen--md-up($withoutSuffix: true) {
            float: right;
        }

        span:first-child {
            padding-right: 1.5rem;
        }
    }

    &__links {
        flex: 3;
        display: flex;
        justify-content: space-evenly;
        font-size: 16px;
        line-height: 2;
        padding: 2rem 0;
        flex-direction: column;

        @include screen--md-up($withoutSuffix: true) {
            padding: 0.5rem 0 0;
            flex-direction: row;
        }

        ul {
            font-size: 14px;
            line-height: 1.8;
        }

        a {
            text-decoration: none;
            color: $color-grey-lighter;
            transition: color 0.5s ease;

            &:hover {
                color: $color-white;
            }
        }
    }

    hr {
        display: none;

        @include screen--md-up($withoutSuffix: true) {
            display: block;
            border-color: $color-black-lightest;
            border-style: solid;
        }
    }
}
