﻿@import '../globals/media-queries';

.full-width-banner {
    overflow: hidden;
    max-height: 533px;
    width: 100%;
    max-width: $breakpoint-large;
    margin: auto;

    @include screen--md-up($withoutSuffix: true) {
        border-radius: 5px;
    }
}
