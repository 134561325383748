﻿@import '../variables/colors';
@import '../variables/fonts';
@import '../variables/z-index';
@import '../globals/media-queries';

.modal {
    position: fixed;
    z-index: $z-index-modal-overlay;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    display: none;
    background-color: rgba(0,0,0,0.85);

    &__content {
        margin: auto;
        color: $color-black;
        width: 100%;
        height: 100%;
        display: flex;
        position: relative;

        @include screen--md-up($withoutSuffix: true) {
            width: 70%;
        }

        @include screen--lg-up($withoutSuffix: true) {
            width: 50%;
        }

        &--leseprobe {
            background-color: $color-white;

            @include screen--md-up($withoutSuffix: true) {
                width: 80%;
                height: 70%;
                padding: 20px;
            }

            @include screen--lg-up($withoutSuffix: true) {
                width: 93%;
                height: 87%;
                padding: 20px;
                margin-top: 3%;
            }
        }

        &--coverzoom, &--special-gift {
            height: 100%;

            @include screen--md-up($withoutSuffix: true) {
                width: auto;
                height: 90%;
            }
        }
    }

    &__book {
        width: 100%;
    }

    &__close {
        font-weight: $font-weight-semi-bold;
        float: right;
        font-size: 35px;
        position: absolute;
        right: 5px;
        color: $color-black;

        @include screen--md-up($withoutSuffix: true) {
            color: $color-white;
            top: -2.5rem;
            right: 0;
        }

        &--coverzoom, &--special-gift {
            color: $color-white;
        }

        &:hover,
        &:focus {
            text-decoration: none;
            cursor: pointer;
        }
    }

    &--coverzoom, &--special-gift {
        background-color: $color-black;

        @include screen--md-up($withoutSuffix: true) {
            background-color: rgba(0,0,0,0.85);
        }
    }
}

.coverzoom-image {
    height: 100%;
    width: 100%;
    object-fit: contain;

    @include screen--md-up($withoutSuffix: true) {
        width: auto;
    }
}
