﻿$font-primary: 'Niramit', sans-serif;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;
$font-weight-black: 900;

/* niramit-500 - latin-ext */
@font-face {
    font-family: 'Niramit';
    font-style: normal;
    font-weight: 500;
    src: url('./gfx/niramit-v10-latin-ext-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./gfx/niramit-v10-latin-ext-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* niramit-600 - latin-ext */
@font-face {
    font-family: 'Niramit';
    font-style: normal;
    font-weight: 600;
    src: url('./gfx/niramit-v10-latin-ext-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./gfx/niramit-v10-latin-ext-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* niramit-700 - latin-ext */
@font-face {
    font-family: 'Niramit';
    font-style: normal;
    font-weight: 700;
    src: url('./gfx/niramit-v10-latin-ext-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./gfx/niramit-v10-latin-ext-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}