﻿@import '../variables/colors';
@import '../variables/fonts';
@import '../variables/z-index';
@import '../globals/media-queries';

.call-to-action {
    display: flex;
    font-family: $font-primary;
    background-color: $color-black-lighter;
    box-shadow: 0px -5px 20px rgba(0, 0, 0, 0.2);
    z-index: $z-index-call-to-action;
    position: fixed;
    justify-content: space-between;
    align-items: center;
    left: 0;
    bottom: 0;
    height: 119px;
    flex-wrap: wrap;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    padding: 0 1rem;
    width: calc(100% - 2rem);

    @include screen--sm-up($withoutSuffix: true) {
        height: 90px;
        flex-wrap: inherit;
        border-radius: 0;
        padding: 0 1rem;
        width: calc(100% - 2rem);
        justify-content: space-evenly;
    }

    @include screen--md-up($withoutSuffix: true) {
        padding: 0 5rem;
        width: calc(100% - 10rem);
    }

    @include screen--md-content-up($withoutSuffix: true) {
        background-color: transparent;
        height: 50px;
        position: inherit;
        padding: 0;
        width: 100%;
        box-shadow: none;
        justify-content: space-between;
    }

    &__toggle {
        font-weight: $font-weight-regular;
        font-size: 16px;
        line-height: 22px;
        color: $color-white;
        display: inherit;
        justify-content: space-between;
        width: 100%;
        padding: 1rem 0;
        position: relative;

        @include screen--sm-up($withoutSuffix: true) {
            width: 60%;
            padding: 0;
        }

        @include screen--lg-up($withoutSuffix: true) {
            width: 40%;
            padding: 0;
        }

        &:after {
            content: '';
            display: block;
            width: 100%;
            border-bottom: 1px solid $color-black-lightest;
            position: absolute;
            bottom: 0;

            @include screen--sm-up($withoutSuffix: true) {
                content: '';
                display: block;
                height: 24px;
                border-right: 1px solid $color-white;
                margin: auto 0;
                width: unset;
                border-bottom: 0;
                width: 90%;
                margin: 0 5%;
                position: relative;
            }
        }
    }

    &__price {
        font-family: $font-primary;
        font-weight: $font-weight-bold;
        font-size: 24px;
        line-height: 28px;
        color: $color-white;
        text-align: start;

        @include screen--sm-up($withoutSuffix: true) {
            font-size: 32px;
            text-align: center;
            width: 40%;
        }

        @include screen--lg-up($withoutSuffix: true) {
            width: 30%;
            text-align: left;
        }
    }

    &__button {
        text-align: end;
    }
} 
