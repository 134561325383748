﻿@import "../variables/colors";
@import "../variables/fonts";
@import "../variables/index";
@import "../variables/z-index";
@import "../globals/media-queries";

.submenu {
    display: none;
    position: absolute;
    top: 123px;
    left: 0;
    background: $color-black-light;
    margin: 0 calc(50% - 50vw);
    width: 100vw;
    top: 73px;
    visibility: visible;
    transform: scaleY(0);
    transform-origin: top center;

    @include screen--md-up($withoutSuffix: true) {
        display: flex;
    }

    &--active {
        visibility: visible;

        @include screen--md-up($withoutSuffix: true) {
            transform: scaleY(1);
        }
    }

    &--transition {
        transition: transform 0.3s ease-in-out;
    }

    &__title {
        border-bottom: 1px solid $color-black-lightest;
        padding: 0 0 1rem;
    }

    &__container {
        max-width: calc(#{$breakpoint-content-width} + 8rem);
        margin: 0 auto;
        width: 100%;
        position: relative;
        padding: 2rem;
    }

    &__items {
        display: flex;
        width: 100%;
        padding: 1rem 0 0;
        flex-wrap: wrap;
    }

    &__link {
        width: 25%;
        text-decoration: none;
        color: $color-grey-lighter;
        padding: 0.5rem 0;
    }

    &__close > svg {
        width: 20px;
        position: absolute;
        right: 2rem;
        top: 35px;
        cursor: pointer;
    }
}
