﻿@import '../variables/colors';
@import '../variables/fonts';

.two-rows-table {
    font-family: $font-primary;
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    font-size: 14px;

    @include screen--md-up($withoutSuffix: true) {
        font-size: 16px;
    }

    tr {
        text-align: left;
        background-color: $color-black-lightest;
    }

    tr:nth-child(even) {
        background-color: $color-black-lighter;
    }

    td {
        padding: 10px 15px;
        color: #fff;
    }

    td:nth-child(even) {
        color: $color-grey-lighter;
    }
}
