@import '../variables/colors';
@import '../variables/breakpoints';

@mixin accordion() {
    .accordion {
        border-radius: 5px;
        font-size: 1rem;
        margin: 0 auto;
        width: 100%;
    }

    .accordion-header {
        font-weight: 500;
        letter-spacing: 0.1rem;
        margin: 0 auto;
        max-width: $breakpoint-small;
        padding: 1rem;
        font-size: 12px;
        transition: all 0.3s;

        @include screen--md-up($withoutSuffix: true) {
            font-size: 14px;
        }
    }

    .accordion-body {
        height: 0;
        transition: height 0.3s ease-in, background 0.2s ease-in;
        padding-top: 0.3rem;

        &__contents {
            font-size: 14px;
            font-weight: 300;
            letter-spacing: 0.1rem;
            max-width: $breakpoint-small;
            opacity: 0;
            padding: 1.5rem 0rem;
            transform: translateY(4%);
            transition: visibility 0.3s ease-in;
            visibility: hidden;
        }
    }

    .accordion__item.active > .accordion-body {
        height: auto;
    }

    .accordion__item.active > .accordion-body > .accordion-body__contents {
        opacity: 1;
        transform: translateY(0);
        transition: opacity 0.5s ease-in, transform 0.3s ease-in;
        transition-delay: 0.1s;
        visibility: visible;
    }

    .accordion__item.active:last-child .accordion-header {
        border-radius: none;
    }

    .accordion:first-child > .accordion__item > .accordion-header {
        border-bottom: 1px solid transparent;
    }

    .accordion__item {
        border-bottom: 1px solid $color-black-lightest;
        cursor: pointer;
        padding: 0.5rem 0;

        &:first-child {
            border-top: 1px solid $color-black-lightest;
        }
    }

    .accordion__item > .accordion-header::after {
        content: '';
        background: url('./gfx/arrow-down.svg') no-repeat center;
        float: right;
        position: relative;
        width: 10px;
        height: 10px;
        top: 12px;
        transform: rotate(0deg);
        transition: 0.3s all;
        background-size: 100%;
    }

    .accordion__item.active > .accordion-header::after {
        transform: rotate(-180deg);
    }
}

.accordion--mobile-only {
    @media screen and (max-width: $breakpoint-medium) {
        @include accordion();

        .accordion-header {
            padding: 1rem 1rem 1rem 0;
        }
    }
}
