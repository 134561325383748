﻿@import "../globals/media-queries";
@import "../variables/colors";
@import "../variables/fonts";
@import "../variables/z-index";
@import "../variables/breakpoints";

.calendar {
    position: relative;

    &__divider {
        border-top: 1px solid $color-black-lightest;
        border-bottom: none;
        margin: 0 0 1.5rem 0;
        border-right-width: 0;
        border-left-width: 0;

        @include screen--md-up($withoutSuffix: true) {
            margin: 0 0 3rem 0;
        }
    }

    &__results {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 1rem 0;

        .product-tile__container {
            @include screen--md-up($withoutSuffix: true) {
                padding: 1rem 2.5rem;
            }
        }
    }

    &__title {
        width: 100%;
        padding: 1rem 0;
        background: $color-black-lighter;
        position: sticky;
        z-index: $z-index-carousel-content;
        top: 0;

        @include screen--md-up($withoutSuffix: true) {
            position: relative;
        }
    }

    &__month {
        font-size: 18px;
        font-weight: $font-weight-semi-bold;
        padding: 0 1rem 0 2rem;

        &:before {
            content: '';
            width: 20px;
            height: 20px;
            display: inline-block;
            background: url(./gfx/calendar.svg) no-repeat;
            position: relative;
            top: 4px;
            right: 10px;
        }
    }

    &__result-amount {
        font-size: 14px;
        color: $color-grey-lighter;
        border-left: 1px solid $color-black-lightest;
        padding: 0 1rem;
    }
}
