﻿@import '../variables/colors';
@import '../variables/fonts';
@import '../globals/media-queries';
@import '../variables/breakpoints';
@import '../cards/layout';

// blog has two type of cards: large (featured article cards) and small (regular article cards)
// frontpage has one type of card, but uses a bit of both small and large's design. 

//Blogpage articles
.blog {
    margin: 0 calc(50% - 50vw);
    width: 100vw;
    height: 100%;

    &__header {
        width: 100%;
        height: 348px;
        position: relative;
        background-size: cover;
        background-position-y: -126px;
        color: $color-white;
        margin-top: -123px;

        @include screen--md-up($withoutSuffix: true) {
            height: 500px;
        }
    }

    &__header-content-container {
        max-width: $breakpoint-large;
        margin: auto;
    }

    &__header-content {
        position: absolute;
        bottom: 30px;
        width: 100%;
        text-align: center;
        color: $color-grey-lightest;

        @include screen--md-up($withoutSuffix: true) {
            text-align: left;
        }
    }

    &__header-title {
        padding: 0 40px;

        @include screen--lg-up($withoutSuffix: true) {
            margin-left: 0px;
            padding: 0;
        }
    }

    &__header-paragraph {
        padding: 0 40px;
        font-size: 12px;

        @include screen--lg-up($withoutSuffix: true) {
            padding: 0;
            margin-left: 0px;
        }
    }

    &__article-container {
        max-width: $breakpoint-large;
        margin: auto;
        margin-top: 33px;
        padding-bottom: 40px;

        @include screen--md-up($withoutSuffix: true) {
            padding-bottom: 89px;
            margin-top: 59px;
        }
    }

    &__featured-articles {
        @include standard-card-container-layout();
        justify-content: center;

        @include screen--lg-up($withoutSuffix: true) {
            justify-content: space-between;
        }
    }

    &__articles {
        @include standard-card-container-layout();
        justify-content: center;

        @include screen--lg-up($withoutSuffix: true) {
            justify-content: space-between;
        }
    }

    &__articles-title {
        text-align: left;
        margin-top: 34px;
        margin-left: 15px;

        @include screen--sm-up($withoutSuffix: true) {
            text-align: center;
            margin-left: 0;
        }

        @include screen--lg-up($withoutSuffix: true) {
            text-align: left;
        }
    }

    &__paginator {
        width: 100%;
        height: 50px;
        margin-top: 46px;
        display: flex;
        justify-content: center;
    }
}

.blog-page-content {
    margin-top: 44px;
}

//Frontpage blog articles
.blog-news {
    width: 100%;
    display: flex;
    margin: auto;
    text-align: center;
    font-family: $font-primary;
    flex-direction: column;
    max-width: $breakpoint-large;

    @include screen--md-up($withoutSuffix: true) {
        padding: 0;
    }

    &__title {
        font-size: 32px;
        line-height: 34px;
        font-weight: $font-weight-semi-bold;
        color: $color-black;
        padding-bottom: 30px;
        margin-top: 40px;

        @include screen--md-up($withoutSuffix: true) {
            padding-bottom: 50px;
        }
    }

    &__content {
        &:after {
            content: 'flickity';
            display: none;
        }

        @include screen--md-up($withoutSuffix: true) {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin: auto;
            flex-wrap: wrap;
            margin-bottom: 30px;
            padding: 0 2rem;

            &:after {
                content: '';
            }
        }

        @include screen--lg-up($withoutSuffix: true) {
            padding: 0;
        }
    }

    &__button {
        margin: 30px auto 80px;
    }

    &__button-text {
        font-size: 14px;
        line-height: 16px;

        &:after {
            content: '';
            width: 22px;
            height: 19px;
            display: inline-block;
            background: url('./gfx/arrow-forward.svg') no-repeat;
            position: relative;
            top: 10px;
            right: -13px;
        }
    }

    .flickity-page-dots, .flickity-prev-next-button {
        display: none;
    }
}

.back-button {
    margin-bottom: 44px;

    &__text {
        color: $color-black;
        text-align: center;
        padding: 1rem;
        font-weight: $font-weight-semi-bold;
        font-size: 16px;

        @include screen--md-up($withoutSuffix: true) {
            font-size: 18px;
        }

        &:before {
            content: '';
            width: 22px;
            height: 19px;
            display: inline-block;
            background: url('./gfx/backbutton-arrow.svg') no-repeat;
            position: relative;
            top: 7px;
            right: 10px;
            color: $color-black;
        }
    }

    &:hover {
        text-decoration: none;
    }
}

