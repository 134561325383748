﻿@import '../variables/colors';
@import '../variables/fonts';

.product-tab-block {
    float: none;
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;

    &:after {
        content: '';
        display: table;
        clear: both;
    }

    input[type=radio] {
        display: none;
        flex: 0;
    }

    label {
        display: block;
        color: $color-grey-light;
        font-size: 30px;
        font-weight: $font-weight-regular;
        text-decoration: none;
        text-align: center;
        line-height: 2;
        cursor: pointer;
        border-bottom: 2px solid $color-black-lightest;
        font-family: $font-primary;
        font-size: 14px;
        line-height: 22px;
        font-weight: $font-weight-regular;
        transition: 0.3s ease;
        line-height: 2.75em;
        height: 3em;
        flex: 1;

        @include screen--md-up($withoutSuffix: true) {
            font-size: 16px;
        }

        i {
            padding: 5px;
            margin-right: 0;
        }

        &:hover {
            border-bottom: 2px solid $color-grey-lighter;
        }
    }

    [id^="tab"]:checked + label {
        border-bottom: 2px solid $color-pink;
        color: $color-pink;
        font-weight: $font-weight-semi-bold;
    }

    &__content {
        display: none;
        width: 100%;
        padding: 20px 0;
        box-sizing: border-box;

        @include screen--md-up($withoutSuffix: true) {
            padding: 15px 0;
        }
    }

    #tab1:checked ~ #tab-content1,
    #tab2:checked ~ #tab-content2,
    #tab3:checked ~ #tab-content3 {
        display: block;
    }
}


