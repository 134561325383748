﻿@import "../variables/colors";
@import "../variables/fonts";
@import "../variables/index";
@import "../globals/media-queries";

.social {
    width: 100%;
    display: flex;
    padding-top: 0.3rem;

    &--blogpage {
        margin-bottom: 30px;

        @include screen--md-up($withoutSuffix: true) {
            margin-bottom: 56px;
        }
    }

    &__text {
        font-family: $font-primary;
        font-weight: $font-weight-regular;
        font-size: 12px;
        line-height: 22px;
        margin: 9px 5px 0 0;

        @include screen--md-up($withoutSuffix: true) {
            font-size: 14px;
        }

        &--grey {
            color: $color-grey-lighter;
        }

        &--black {
            color: $color-black;
        }
    }

    &--even {
        justify-content: space-evenly;
    }

    &--right {
        justify-content: flex-end;

        .social-share {
            display: none;

            @include screen--lg-up($withoutSuffix: true) {
                display: flex;
            }
        }
    }

    &--left {
        justify-content: flex-start;

        .social-share {
            display: none;

            @include screen--lg-up($withoutSuffix: true) {
                display: flex;
            }
        }
    }

    &__link {
        background: $color-black-lightest;
        border-radius: 50%;
        height: 25px;
        width: 25px;
        transition: background-color 0.3s ease;

        @include screen--lg-up($withoutSuffix: true) {
            font-size: 14px;
            height: 34px;
            width: 34px;
        }

        &--border {
            background: transparent;
            margin-left: 10px;
            transition: background-color 0.3s ease;
            height: 34px;
            width: 34px;

            &--light {
                border: 1px solid $color-black-lightest;
            }

            &--black {
                border: 1px solid $color-black;
            }

            &:hover {
                @include screen--md-up($withoutSuffix: true) {
                    background: $color-black-lightest;
                }
            }
        }

        &--color {
            margin-left: 10px;
            transition: background-color 0.3s ease;
            height: 34px;
            width: 34px;

            &--twitter {
                background-color: $color-blue-medium;

                &:hover {
                    background: $color-black-lightest;
                }
            }

            &--facebook {
                background-color: $color-blue-dark;

                &:hover {
                    background: $color-black-lightest;
                }
            }
        }

        &--color-blue {
            background: $color-blue-dark;
            margin-left: 10px;
            transition: background-color 0.3s ease;
            height: 34px;
            width: 34px;

            &:hover {
                background: $color-black-lightest;
            }
        }

        &--color-light-blue {
            background: $color-blue-medium;
            margin-left: 10px;
            transition: background-color 0.3s ease;
            height: 34px;
            width: 34px;

            &:hover {
                background: $color-black-lightest;
            }
        }

        &:after {
            content: '';
            display: block;
            height: 25px;
            width: 25px;

            @include screen--lg-up($withoutSuffix: true) {
                font-size: 14px;
                height: 34px;
                width: 34px;
            }
        }

        &:hover {
            @include screen--md-up($withoutSuffix: true) {
                background: $color-grey;
                color: $color-white;
            }
        }
    }

    &__instagram:after {
        background: url('./gfx/insta.svg') no-repeat center;
    }

    &__twitter:after {
        background: url('./gfx/twitter.svg') no-repeat center;
    }

    &__facebook:after {
        background: url('./gfx/facebook.svg') no-repeat center;
    }

    &__twitter-white:after {
        background: url('./gfx/twitter-white.svg') no-repeat center;
    }

    &__facebook-white:after {
        background: url('./gfx/facebook-white.svg') no-repeat center;
    }

    &__pinterest:after {
        background: url('./gfx/pinterest.svg') no-repeat center;
    }

    &__some {
        display: flex;
        cursor: pointer;
        margin-top: 8px;
        height: 25px;
        width: 25px;

        @include screen--lg-up($withoutSuffix: true) {
            display: none;
        }

        &--grey:after {
            background: url('./gfx/SoMe-grey.svg') no-repeat center;
        }

        &--black:after {
            background: url('./gfx/SoMe.svg') no-repeat center;
        }
    }
}
