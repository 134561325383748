﻿@import "../variables/colors";
@import "../variables/fonts";
@import "../variables/index";
@import "../globals/media-queries";
@import '../popups/mobile-popup-mixin';

$shadow-weight-sides: 240px;
$shadow-weight-bottom: 400px;
$age-limit-size: 50px;
$age-limit-size-mobile: 30px;

.dropdown-menu {
    @include standard-mobile-bottom-popup-style();

    @include screen--md-up($withoutSuffix: true) {
        margin: 10px 0 0;
        position: absolute;
        width: 100%;
        z-index: $z-index-dropdown-content;
        background: $color-black-light;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        border-top-right-radius: 0px;
        border-top-left-radius: 0px;
        text-align: left;
        visibility: visible;
        transform: translateY(-20%);
        display: block;
        transition: transform 0.2s ease-in, opacity 0.3s ease-in,;
        bottom: auto;
        opacity: 0;
    }

    &__item {
        color: $color-white;
        padding: 12px 0;
        text-decoration: none;
        display: block;
        cursor: pointer;
        width: calc(100% - 20px);
        border-bottom: 1px solid $color-black-lightest;
        margin: 0 10px;

        &:last-child {
            border-bottom: none;
        }
    }

    &--active {
        transform: translateY(0%);
        opacity: 1;
    }

    &--background {
        z-index: $z-index-background-elements;
    }

    &--catalog {
        box-shadow: none;
        font-size: 14px;
        text-align: center;
        background: $color-black-light;

        @include screen--md-up($withoutSuffix: true) {
            margin: -3px 0;
            max-width: 205px;
        }
    }
}