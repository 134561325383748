﻿@import '../variables/colors';
@import '../variables/fonts';
@import "../globals/media-queries";

.read-more-block {
    width: 100%;
    color: $color-grey-lighter;
    font-family: $font-primary;
    font-weight: $font-weight-regular;
    font-size: 16px;
    line-height: 24px;
    $self: &;

    &__paragraph {
        font-size: 14px;
        line-height: 18px;
        height: 60px;
        overflow: hidden;
        transition: height 0.3s ease;
        padding: 0 0 15px;
        text-align: left;

        @include screen--md-up($withoutSuffix: true) {
            line-height: 24px;
            font-size: 16px;
            padding: 10px 0;
        }
    }

    &__button {
        cursor: pointer;
        text-align: center;
        width: 25px;
        height: 25px;
        line-height: 25px;
        border-radius: 50%;
        background: $color-black-lighter url(./gfx/arrow.svg) no-repeat;
        background-position-x: center;
        background-position-y: center;
        transition: 0.3s ease;
        transform: rotate(0deg);
    }

    &__button-container {
        width: 100%;
        position: relative;
        background-image: linear-gradient(transparent, $color-black);
        margin-top: -44px;
        display: flex;
        justify-content: center;
        padding-top: 2.5rem;

        &:before,
        &:after {
            content: "";
            height: 1px;
            background: $color-black-lightest;
            flex: 1;
            margin-top: 0.8rem;
        }
    }
}

.reveal--open {
    .read-more-block__button {
        transform: rotate(180deg);
    }
}
