@import '../variables/colors';
@import '../variables/fonts';
@import '../globals/media-queries';

$product-item-width: 115px;
$product-item-width--desktop: 195px;
$age-limit-size: 34px;
$age-limit-size-mobile: 28px;

.product-tile {
    $self: &;

    &--catalog {
        #{ $self }__container {

            @include screen--md-up($withoutSuffix: true) {
                padding: 1rem 2.5rem;
            }
        }

        #{ $self }__image {
            margin: 0;

            img {
                min-height: 175px;
                background-color: $color-grey-light;
            }
        }
    }

    &__age {
        position: absolute;
        height: $age-limit-size-mobile;
        width: $age-limit-size-mobile;
        top: calc(#{$age-limit-size-mobile} / 2 * -1);
        right: calc(#{$age-limit-size-mobile}/ 2 * -1);
        background: $color-pink;
        border-radius: 50%;
        font-size: 12px;
        font-weight: $font-weight-bold;
        text-align: center;
        line-height: 230%;
        color: $color-white;

        @include screen--md-up($withoutSuffix: true) {
            height: $age-limit-size;
            width: $age-limit-size;
            top: calc(#{$age-limit-size} / 2 * -1);
            right: calc(#{$age-limit-size}/ 2 * -1);
            background: $color-pink;
            font-size: 14px;
            font-weight: $font-weight-semi-bold;
            line-height: 230%;
        }
    }

    &__container {
        width: $product-item-width;
        text-align: center;
        padding: 8px 15px;
        position: relative;
        margin: 1rem 0 0;

        @include screen--md-up($withoutSuffix: true) {
            padding: 0 9px;
            width: $product-item-width--desktop;
            margin: 1.5rem 0 0;
        }
    }

    &__product-link {
        text-decoration: none;
        color: $color-black;
    }

    &__image {
        position: relative;
        transition: box-shadow 0.5s ease;

        img {
            width: 100%;
            border-radius: 5px;
            box-shadow: 0px 10px 15px 0px rgba(0,0,0,0.09);
            height: 175px;

            @include screen--md-up($withoutSuffix: true) {
                height: 282px;
            }

            &:hover {
                box-shadow: 2px 2px 20px -1px rgba(0,0,0,0.53);
            }
        }

        &:hover {
            box-shadow: 2px 2px 20px -1px rgba(0,0,0,0.53);
        }
    }

    &__title {
        padding: 1.5rem 0 0;
        display: block;
        font-size: 16px;

        @include screen--md-up($withoutSuffix: true) {
            padding: 1.8rem 0 0;
        }

        &--inverted {
            color: $color-white;
        }
    }

    &__link {
        position: absolute;
        width: 80%;
        height: 90%;
        left: 10%;
        z-index: 2;
    }

    &__price {
        display: block;
        padding: 7px 11px;
        color: $color-white;
        background-image: url('./gfx/label-shape_top-black.svg'), url('./gfx/label-shape_bottom-black.svg'), linear-gradient(to bottom, transparent 5%, $color-black 5%, $color-black 95%, transparent 10%);
        background-position: top, bottom, center;
        background-repeat: no-repeat;
        background-size: 100%, 100%;
        position: absolute;
        bottom: -10px;
        left: 0;
        right: 0;
        margin: 0 auto;
        font-size: 12px;
        max-width: calc(#{$product-item-width} - 60px);
        width: fit-content;
        font-family: $font-primary;
        font-weight: $font-weight-medium;

        @include screen--md-up($withoutSuffix: true) {
            font-size: 14px;
        }

        &--inverted {
            color: $color-black;
            background-image: url('./gfx/label-shape_top.svg'), url('./gfx/label-shape_bottom.svg'), linear-gradient(to bottom, transparent 5%, $color-white 5%, $color-white 95%, transparent 10%);
        }
    }
}