﻿@import "reset";
@import "media-queries";
@import "typography";
@import "../variables/colors";
@import "../variables/fonts";
@import "../variables/breakpoints";

html {
    scroll-behavior: smooth;
}

body {
    overflow-x: hidden;
    background: $color-black;
    color: $color-white;
    font-family: $font-primary;
}

img {
    display: block;
}

.no-scroll {
    overflow: hidden;
}

.hide {
    display: none;
}

.hidden {
    visibility: hidden;
}

.hide-on-desktop {
    @include screen--md-up($withoutSuffix: true) {
        display: none;
    }
}