﻿@import '../variables/colors';
@import '../variables/fonts';

.image-two-rows-block {
    width: 100%;
    height: auto;
    display: flex;

    &__image {
        min-width: 85px;
        height: 85px;
        background-color: $color-grey-light;
        border-radius: 50%;
    }

    &__paragraph {
        font-family: $font-primary;
        text-align: left;

        &--large {
            height: auto;
            font-weight: $font-weight-regular;
            color: $color-white;
            padding: 0.2rem 0 0 2rem;
            font-size: 14px;
            line-height: 18px;

            @include screen--md-up($withoutSuffix: true) {
                font-size: 16px;
                line-height: 24px;
            }
        }

        &--small {
            height: auto;
            color: $color-grey-light;
            font-style: italic;
            font-weight: $font-weight-regular;
            font-size: 12px;
            line-height: 14px;
            padding: 0.6rem 0 0 2rem;

            @include screen--md-up($withoutSuffix: true) {
                font-size: 16px;
                line-height: 22px;
            }
        }
    }
}