﻿@import '../variables/colors';
@import '../variables/fonts';
@import '../variables/z-index';
@import '../globals/media-queries';

@mixin standard-mobile-bottom-popup-style () {
    display: flex;
    font-family: $font-primary;
    width: 100%;
    background-color: $color-black-lighter;
    box-shadow: 0px -5px 20px rgba(0,0,0,0.2);
    z-index: $z-index-pop-up-overlay;
    position: fixed;
    justify-content: space-evenly;
    left: 0;
    bottom: 0;
    flex-direction: column;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    align-items: start;
    transform: translateY(100%);
    transition: transform 0.5s ease-in;
    visibility: visible;
}
